/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #444444;
  font-size: 14px;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

#footer .footer-top {
  padding: 60px 0 60px 0;
  background: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 26px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 300;
}

#footer .footer-top .footer-contact p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #8A9AB7;
  font-weight: 500;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #211F15;
  position: relative;
  padding-bottom: 12px;
  font-weight: 600;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #2487ce;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: #8A9AB7;
}

.footer-contact p {
  color: #8A9AB7;
  margin-top: 30px;
  font-weight: 500;

}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #8A9AB7;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-weight: 500;
}



#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 5px;
  text-align: left;
  border: 1px solid #cde5f6;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: -1px;
  right: -2px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #2487ce;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 5px 5px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: #EB00FF;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #8A9AB7;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #2487ce;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 5px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .copyright p {
  color: #8A9AB7;
  vertical-align: middle;
}

#footer .social-links a:hover {
  background: #EB00FF;
  color: #fff;
  text-decoration: none;
}

.beneficios h5 {
  text-transform: uppercase;
  color: #35E0E7;
}

.beneficios h2 {
  color: #161521;
  font-weight: 600;
}

.sec-heading {
  margin-top: 50px;
}

.sec-heading h5 {
  color: #35E0E7;
  text-transform: uppercase;
}

.sec-heading h2 {
  color: #211F15;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

.como {
  padding-bottom: 220px;
}

.como p {
  color: #8A9AB7;
  font-size: 18px;
  width: 90%;
}

.cambio .icon-box {
  padding: 20px;
  border: 2px solid transparent;
}

.cambio .icon-box:hover {
  background: linear-gradient(white, white) padding-box, linear-gradient(#EB00FF, #1BFFE4) border-box;
  border-radius: 12px;

}

.cambio .icon-box h4 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.redefine {
  background: linear-gradient(180deg, #EB00FF 1.91%, #1BFFE4 99.45%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  /* background-image: url(../img/redefine.svg);
  background-size: cover;
  background-repeat: no-repeat; */
  padding: 150px 0;
  /* padding-top: 180px; */
  background-position: 100% 100%;
}

.redefine h5 {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}

.redefine h2 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-top: 20px;
}

.redefine .btn-get-started:hover {
  color: #fff;
}

.antes {
  background-color: #211F15;
}


.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: linear-gradient(#211f15, #211f15) padding-box,
    linear-gradient(#EB00FF, #1BFFE4) border-box;
  border-radius: 12px;
  border: 2px solid transparent;
  padding: 10px 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
}

.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #1BFFE4;
}

.nav-pills .nav-link {
  border: 2px solid #211f15;
}

.icon-boxes {
  background: #FAFBFF;
  padding: 30px;
  border-radius: 40px;
}

.boxes {
  background-color: #FAFBFF;
  padding: 50px;
  border-radius: 30px;
}

.cambio .icon-box {
  background-color: #fff;
  border-radius: 10px;
}
