#hero {
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(178.33deg, #EB00FF 0.35%, #1BFFE4 110.47%); */
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%); */
  position: relative;
  background-image: url('/assets/hero-bg.png');
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;

}

#hero .container {
  padding-top: 80px;
}

#hero img {
  position: relative;
  z-index: 999;
  top: 0;
}

#hero h1 {
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #ffffff;
}

#hero p {
  font-size: 24px;
  margin-top: 20px;
  color: #fff;
}

#hero h2 {
  color: #5e5e5e;
  margin: 10px 0 0 0;
  font-size: 22px;
}

.btn-get-started {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 14px 20px;
  border-radius: 10px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  border: 2px solid #fff;
  width: 200px;
  text-align: center;
  margin: 5px;
  margin-top: 50px;
}

.btn-get-started:hover {
  background: transparent;
  color: #fff;
}


.beneficios .icon-box {
  padding: 20px;
  position: relative;
  overflow: hidden;
  background: #fff;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  margin-top: 20px;
  border: 2px solid transparent;
}

.beneficios .icon-box:hover {
  background: linear-gradient(white, white) padding-box, linear-gradient(#EB00FF, #1BFFE4) border-box;
  border-radius: 12px;
  border: 2px solid transparent;
}

.beneficios .icon-box .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}


.beneficios .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 36px;
  line-height: 1;
  color: #2487ce;
}


@media (max-height: 800px) {
  #hero {
    height: auto;
  }
}

@media (max-width: 992px) {
  #hero {
    height: auto;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}


@media (max-width: 450px) {

  .mobile-nav-toggle {
      color: #fff;
  }

  #hero h1 {
      font-size: 22px;
      margin-top: 30px;
  }

  #hero p {
      font-size: 14px;
  }

  .btn-get-started {
      width: 80%;
      padding: 15px;
      margin: 10px auto;
      display: block;
  }

  section {
      padding: 30px 0;
  }

  .sec-heading h5 {
      font-size: 16px;
  }

  .sec-heading h2 {
      font-size: 20px;
  }

  #hero {
      height: 110vh;
      padding-bottom: 80px;
  }

  .como p {
      width: auto;
      font-size: 15px;
  }

  .antes .testimonial-item {
      width: 80%;
      padding: 100px 10px;
      height: auto;
  }

  .antes .testimonial-item p {
      font-size: 14px;
  }

  .antes .testimonial-item h2 {
      font-size: 30px;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
      left: 0%;
      right: auto;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
      right: 0%;
      left: auto;
  }

  .antes .swiper-pagination .swiper-pagination-bullet {
      width: 60px !important;
  }

  .antes .swiper-pagination {
      top: 32%;
      padding: 0;
  }

  .redefine h2 {
      font-size: 20px;
      margin-bottom: 30px;
  }

  .redefine h5 {
      font-size: 16px;
  }

  .redefine {
      padding: 80px 30px;
  }

  .navbar .getstarted,
  .navbar .getstarted:focus {
      border-color: #000;
      color: #000;
  }

  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
      color: #EB00FF;
  }

  .boxes {
      padding: 30px;
  }

  .icon-boxes {
      padding: 30px;
  }
  .como{
      padding-bottom: 30px;
  }
  #footer .footer-top{
      padding: 30px 0 0 0;
  }

}