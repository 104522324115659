// Section 1

.beneficios .icon-box {
  padding: 20px;
  position: relative;
  overflow: hidden;
  background: #fff;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  margin-top: 20px;
  border: 2px solid transparent;
}

.beneficios .icon-box:hover {
  background: linear-gradient(white, white) padding-box, linear-gradient(#EB00FF, #1BFFE4) border-box;
  border-radius: 12px;
  border: 2px solid transparent;
}

.beneficios .icon-box .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 16px;
}


.beneficios .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 36px;
  line-height: 1;
  color: #2487ce;
}

.beneficios h5 {
  text-transform: uppercase;
  color: #35E0E7;
}

.beneficios h2 {
  color: #161521;
  font-weight: 600;
}

// Section 2

.como {
  padding-bottom: 220px;
}

.como p {
  color: #8A9AB7;
  font-size: 18px;
  width: 90%;
}

.como .btn-learn-more {

  background: linear-gradient(white, white) padding-box,
    linear-gradient(#EB00FF, #1BFFE4) border-box;
  border-radius: 12px;
  border: 2px solid transparent;
  padding: 10px 30px;
}

// Section 3

.services {
  background: #FAFBFF;
  padding-bottom: 120px;
  padding-top: 40px;
}

.services .icon-box {
  text-align: left;
  padding: 30px;
  transition: all ease-in-out 0.3s;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px 2px rgb(198 198 255 / 34%);
  border-radius: 14px;
  margin-top: 30px;
  border: 2px solid transparent;
}

.services .icon-box:hover {
  background: linear-gradient(white, white) padding-box, linear-gradient(#EB00FF, #1BFFE4) border-box;
  border-radius: 12px;

}

.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #f5f5f5;
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 18px;
  color: #000;
}


.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: #8A9AB7;
  font-weight: 600;
}



.services .iconbox-blue i {
  color: #47aeff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}

.services .iconbox-orange i {
  color: #ffa76e;
}

.services .iconbox-orange:hover .icon i {
  color: #fff;
}

.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}

.services .iconbox-pink i {
  color: #e80368;
}

.services .iconbox-pink:hover .icon i {
  color: #fff;
}

.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}

.services .iconbox-yellow i {
  color: #ffbb2c;
}

.services .iconbox-yellow:hover .icon i {
  color: #fff;
}

.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}

.services .iconbox-red i {
  color: #ff5828;
}

.services .iconbox-red:hover .icon i {
  color: #fff;
}

.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}

.services .iconbox-teal i {
  color: #11dbcf;
}

.services .iconbox-teal:hover .icon i {
  color: #fff;
}

.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}

// Section 4

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.antes .testimonial-item {
  box-sizing: content-box;
  text-align: left;
  background: linear-gradient(169.81deg, #EB00FF 1.91%, #1BFFE4 99.45%);
  width: 30%;
  padding: 200px 30px;
  margin: auto;
  height: 200px;
}

.antes .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.antes .testimonial-item h2 {
  padding-left: 20px;
  font-size: 48px;
  color: #fff;
  font-weight: 700;
}

.antes .testimonial-item .quote-icon-left,
.antes .testimonial-item .quote-icon-right {
  color: #c9e3f5;
  font-size: 26px;
}

.antes .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.antes .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.antes .testimonial-item p {
  padding: 20px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.antes {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  padding: 100px 0;
  padding-bottom: 150px;
}

.antes .swiper-pagination {
  margin-top: 20px;
  position: absolute;
  top: 25%;
}

.antes .nav-pills {
  margin-bottom: 100px !important;

}

.antes .swiper-pagination .swiper-pagination-bullet {
  width: 100px !important;
  height: 3px !important;
  border-radius: 0 !important;
  border: none !important;
}

.antes .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #35E0E7 !important;
}

.swiper {
  position: initial;
}

.swiper-button-next,
.swiper-button-prev {
  top: 60%;
}

.antes .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #2487ce;
}

.antes .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2487ce;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: linear-gradient(#211f15, #211f15) padding-box,
    linear-gradient(#EB00FF, #1BFFE4) border-box;
  border-radius: 12px;
  border: 2px solid transparent;
  padding: 10px 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
}

.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #1BFFE4;
}

.nav-pills .nav-link {
  border: 2px solid #211f15;
}

// Section 5

.redefine {
  background: linear-gradient(180deg, #EB00FF 1.91%, #1BFFE4 99.45%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  /* background-image: url(../img/redefine.svg);
  background-size: cover;
  background-repeat: no-repeat; */
  padding: 150px 0;
  /* padding-top: 180px; */
  background-position: 100% 100%;
}

.redefine h5 {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}

.redefine h2 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-top: 20px;
}

.redefine .btn-get-started:hover {
  color: #fff;
}







@media (max-width: 450px) {

  .mobile-nav-toggle {
    color: #fff;
  }

  #hero h1 {
    font-size: 22px;
    margin-top: 30px;
  }

  #hero p {
    font-size: 14px;
  }

  .btn-get-started {
    width: 80%;
    padding: 15px;
    margin: 10px auto;
    display: block;
  }

  section {
    padding: 30px 0;
  }

  .sec-heading h5 {
    font-size: 16px;
  }

  .sec-heading h2 {
    font-size: 20px;
  }

  #hero {
    height: 110vh;
    padding-bottom: 80px;
  }

  .como p {
    width: auto;
    font-size: 15px;
  }

  .antes .testimonial-item {
    width: 80%;
    padding: 100px 10px;
    height: auto;
  }

  .antes .testimonial-item p {
    font-size: 14px;
  }

  .antes .testimonial-item h2 {
    font-size: 30px;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0%;
    right: auto;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0%;
    left: auto;
  }

  .antes .swiper-pagination .swiper-pagination-bullet {
    width: 60px !important;
  }

  .antes .swiper-pagination {
    top: 32%;
    padding: 0;
  }

  .redefine h2 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .redefine h5 {
    font-size: 16px;
  }

  .redefine {
    padding: 80px 30px;
  }

  .navbar .getstarted,
  .navbar .getstarted:focus {
    border-color: #000;
    color: #000;
  }

  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #EB00FF;
  }

  .boxes {
    padding: 30px;
  }

  .icon-boxes {
    padding: 30px;
  }
  .como {
    padding-bottom: 30px;
  }
  #footer .footer-top{padding: 30px 0 0 0;}

}