/**
* General Header Styles 
*/
#header {
  z-index: 999;
  padding: 15px 0;
  margin-bottom: -80px;
  position: relative;

  .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 0.5px;

    img {
      max-height: 40px;
    }
  }
  
}

@media (max-width: 992px) {
  #header .logo {
    font-size: 28px;
  }
}

/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 18px;
  color: #ffffff;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: 500;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #ffffff;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: transparent;
  border: 1px solid #fff;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 10px;
  color: #fff;
  width: 150px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: transparent;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #2487ce;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #124265;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(10, 38, 58, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #124265;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #2487ce;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #2487ce;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

@media (max-width: 991px) {

  .mobile-nav-toggle {
      color: #fff;
  }

  #hero h1 {
      font-size: 22px;
      margin-top: 30px;
  }

  #hero p {
      font-size: 14px;
  }

  .btn-get-started {
      width: 80%;
      padding: 15px;
      margin: 10px auto;
      display: block;
  }

  section {
      padding: 30px 0;
  }

  .sec-heading h5 {
      font-size: 16px;
  }

  .sec-heading h2 {
      font-size: 20px;
  }

  #hero {
      height: 110vh;
      padding-bottom: 80px;
  }

  .como p {
      width: auto;
      font-size: 15px;
  }

  .antes .testimonial-item {
      width: 80%;
      padding: 100px 10px;
      height: auto;
  }

  .antes .testimonial-item p {
      font-size: 14px;
  }

  .antes .testimonial-item h2 {
      font-size: 30px;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
      left: 0%;
      right: auto;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
      right: 0%;
      left: auto;
  }

  .antes .swiper-pagination .swiper-pagination-bullet {
      width: 60px !important;
  }

  .antes .swiper-pagination {
      top: 32%;
      padding: 0;
  }

  .redefine h2 {
      font-size: 20px;
      margin-bottom: 30px;
  }

  .redefine h5 {
      font-size: 16px;
  }

  .redefine {
      padding: 80px 30px;
  }

  .navbar .getstarted,
  .navbar .getstarted:focus {
      border-color: #000;
      color: #000;
  }

  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
      color: #EB00FF;
  }

  .boxes {
      padding: 30px;
  }

  .icon-boxes {
      padding: 30px;
  }
  .como{
      padding-bottom: 30px;
  }
  #footer .footer-top{
      padding: 30px 0 0 0;
  }

}