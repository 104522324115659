@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;1,500&display=swap");

.App {
  background: linear-gradient(178.62deg, #eb00ff 0.29%, #1bffe4 91.83%);
  font-family: "Montserrat", sans-serif;
  color: #FFFFFF;
  height: 100%;
  &__logo {
    max-width: 90%;
    margin: 60px 0 60px 20px;
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    &-images {
        display: flex;
        justify-content: center;
      & img {
        max-width: 90%;
      }
    }
    &-content {
      max-width: 90%;
      & h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        margin-bottom: 15px;
      }
&-text1 {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 32px;
        }
        &-text2 {
          font-style: italic;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 40px;
        }
      
    }
    &-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & a {
            &:last-child {
                margin-left: 20px;
            }
        }
    }
  }
  &__footer {
    font-size: 15px;
    padding: 10px;
    background-color: white;
    color: gray;
    display: flex;
    justify-content: space-between;
    &-contact {
      margin: 0 10px;
      display: flex;
      flex-flow: column;
    }
    &-links {
      display: flex;
      flex-flow: column;
      &-item {
        margin: 0 10px;
      }
      &-separator {
        display: none;
      }
    }
  }
}

@media (min-width: 1280px) {
  .App {
    height: 100vh;
    &__logo {
        max-width: 288px;
        margin: 70px 0 70px 70px;
      }
    &__main {
        flex-direction: row;
        padding-bottom: 0px;
        &-images {
            & img {
              max-width: 650px;
            }
          }
          &-content {
            max-width: 467px;
            margin-left: 67px;
            & h1 {
              font-size: 48px;
              line-height: 46px;
            }
          }
    }
    &__footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      background-color: white;
      color: gray;
      display: flex;
      justify-content: center;
      &-links {
        flex-flow: row;
        &-separator {
          display: inline;
        }
      }
    }
  }
}
